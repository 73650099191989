<template>
  <div>
    <div>
      <layout-breadcrumb :list="[{'name':'班级列表'},{'name':'学生列表'}]"></layout-breadcrumb>
    </div>
    <Card>
      <p slot="title">
        <Icon type="md-list-box"></Icon>
        {{ className }}
      </p>
      <p slot="extra">
        <Button type="success" icon="md-add" @click="showNewModal">创建学生</Button>
        <Button style="margin-left: 10px;" type="success" icon="md-add" @click="showNewMulModal">批量创建学生</Button>
      </p>
      <div class="student-query">
        <div>
          <Form ref="formInline" :model="query" inline>
            <FormItem prop="username">
              <Input type="text" v-model="query.username" placeholder="学生账号" clearable></Input>
            </FormItem>
            <FormItem prop="nickname">
              <Input type="text" v-model="query.nickname" placeholder="学生名称" clearable></Input>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="queryStudentList()">查询</Button>
            </FormItem>
          </Form>
        </div>
      </div>
      <div>
        <div>
          <Table border :columns="columns12" :data="pageInfo">
            <template slot-scope="{ row, index }" slot="action">
              <Button type="primary" size="small" style="margin-right: 5px" @click="show(index)">编辑</Button>
              <Button type="error" size="small" @click="remove(index)">删除</Button>
            </template>
          </Table>
        </div>
        <div style="text-align: center;margin-top: 10px;">
          <Page :total="pageData.total" :page-size="pageData.pageSize" @on-change="changePageNo"/>
        </div>
      </div>
    </Card>
    <Modal draggable reset-drag-position sticky
           v-model="showStatus"
           :title="'编辑：'+nowItem.nickName+'（'+nowItem.userName+'）'"
           @on-ok="editStudentInfo"
           @on-cancel="cancel">
      <Form :model="editForm" :label-width="80">
        <FormItem label="姓名">
          <Input v-model="editForm.nickName" placeholder="请输入..."></Input>
        </FormItem>
        <FormItem label="学号">
          <Input v-model="editForm.studentNo" placeholder="请输入..."></Input>
        </FormItem>
        <FormItem label="新密码">
          <Input v-model="editForm.password" placeholder="请输入..."></Input>
        </FormItem>
      </Form>
    </Modal>
    <Modal draggable reset-drag-position sticky footer-hide
           v-model="showNewStatus"
           title="创建新学生"
           @on-ok="createOneStudent"
           @on-cancel="newCancel">
      <Form ref="formValidate" :model="newForm" :rules="ruleValidate" :label-width="80">
        <FormItem label="姓名" prop="nickName">
          <Input v-model="newForm.nickName" placeholder="请输入..." clearable></Input>
        </FormItem>
        <FormItem label="学号" prop="studentNo">
          <Input v-model="newForm.studentNo" placeholder="请输入..." clearable></Input>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit('formValidate')">创建</Button>
          <Button @click="handleReset('formValidate')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
    <Modal draggable reset-drag-position sticky footer-hide
           v-model="showNewMulStatus"
           title="批量创建新学生"
           @on-ok="createMulStudent"
           @on-cancel="newMulCancel">
      <Form ref="formValidate2" :model="newMulForm" :rules="ruleValidate" :label-width="80">
        <FormItem label="文件" prop="file">

          <Row>
            <Col span="12">
              <Upload
                  :before-upload="handleUpload"
                  action="#">
                <Button icon="ios-cloud-upload-outline">上传</Button>
              </Upload>
              <div v-if="newMulForm.file !== ''">已选择: {{ newMulForm.file.name }}</div>
            </Col>
            <Col span="12">
              <Button icon="ios-cloud-download-outline" type="primary" @click="getStudentImportTemplate">模版下载</Button>
            </Col>
          </Row>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit2('formValidate2')">创建</Button>
          <Button @click="handleReset('formValidate2')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      nowIndex: '',
      nowItem: '',
      showStatus: false,
      showNewStatus: false,
      showNewMulStatus: false,
      classId: 0,
      className: '',
      pageInfo: [],
      query: {
        username: '',
        nickname: '',
        classId: '',
        enterYear: '',
      },
      editForm: {
        nickName: '',
        studentNo: '',
        password: ''
      },
      newForm: {
        nickName: '',
        classId: '',
        studentNo: ''
      },
      newMulForm: {
        classId: '',
        file: '',
      },
      enterYearList: [],
      pageData: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      columns12: [
        {
          title: '账号',
          key: 'userName',
          width: 150
        },
        {
          title: '姓名',
          key: 'nickName'
        },
        {
          title: '学号',
          key: 'studentNo'
        },
        {
          title: '学届',
          key: 'enterYear'
        },
        {
          title: '学校',
          key: 'schoolName'
        },
        {
          title: '操作',
          slot: 'action',
          width: 150,
          align: 'center',
          fixed: 'right'
        }
      ],
      ruleValidate: {
        nickName: [
          {required: true, message: '姓名必填', trigger: 'blur'}
        ],
        studentNo: [
          {required: true, message: '学号必填', trigger: 'blur'}
        ],
        classId: [
          {required: true, message: '班级必选', trigger: 'change', type: 'number'}
        ],
        file: [
          {required: true, message: '文件必选', trigger: 'blur', type: 'object'}
        ]
      }
    }
  },
  created() {
    let now = new Date();
    let year = now.getFullYear();
    for (let i = year; i >= 2015; i--) {
      this.enterYearList.push(i);
    }
    let query = this.$routeQuery() || false
    if (query) {
      this.classId = query.id
      this.className = query.name
    }
    this.queryStudentList()
  },
  methods: {
    getStudentImportTemplate(){
      this.$downloadExcel('api/teacher/download','批量学生导入模版')
    },
    deleteStudent(index) {
      this.$http.deleteStudent(
          this.pageInfo[index].studentId
      ).then(res => {
        if (res.status == 1) {
          this.$Message.success('删除成功！');
          this.queryStudentList();
        }
      });
    },
    handleUpload(file) {
      console.log(file);
      this.newMulForm.file = file;
      return false;
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.createOneStudent();
        } else {
          this.$Message.error('Fail!');
        }
      })
    },
    handleSubmit2(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.createMulStudent();
        } else {
          this.$Message.error('Fail!');
        }
      })
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    createOneStudent() {
      this.$http.createOneStudent(
          this.newForm.nickName,
          this.classId,
          this.newForm.studentNo
      ).then(res => {
        if (res.status == 1) {
          this.$Message.success('创建成功!');
          this.newCancel();
        }
      })
    },
    createMulStudent() {
      this.$http.createStudent(
          this.newMulForm.file,
          this.classId
      ).then(res => {
        if (res.status == 1) {
          this.$Message.success('创建成功!');
          this.newMulCancel();
          this.queryStudentList()
        }
      });
    },
    showNewModal() {
      this.showNewStatus = true;
    },
    showNewMulModal() {
      this.showNewMulStatus = true;
    },
    queryStudentList() {
      this.$http.queryStudentList(
          this.pageData.current,
          this.pageData.pageSize,
          this.query.username,
          this.query.nickname,
          this.classId
      ).then(res => {
        if (res.status == 1) {
          this.pageInfo = res.data.records;
          this.pageData.total = res.data.total;
        }
      });
    },
    changePageNo(val) {
      this.pageData.current = val;
      this.queryStudentList();
    },
    show(index) {
      this.nowIndex = index;
      this.editForm = this.pageInfo[index];
      this.showStatus = true;
    },
    remove(index) {
      this.$Modal.confirm({
        title: '删除',
        content: '确定要删除此学生吗？',
        onOk: () => {
          this.deleteStudent(index);
        },
        onCancel: () => {

        }
      });
    },
    editStudentInfo() {
      let md5 = require('md5');
      let password = md5(this.editForm.password)
      this.$http.editStudentInfo(
          this.pageInfo[this.nowIndex].studentId,
          this.editForm.nickName,
          this.editForm.studentNo,
          password
      ).then(res => {
        if (res.status == 1) {
          this.$Message.success('编辑成功！');
          this.cancel();
          this.queryStudentList();
        }
      });
    },
    cancel() {
      this.nowIndex = '';
      this.nowItem = '';
      this.editForm = {
        nickName: '',
        studentNo: '',
        password: ''
      };
      this.showStatus = false;
    },
    newCancel() {
      this.newForm = {
        nickName: '',
        classId: '',
        studentNo: ''
      };
      this.showNewStatus = false;
    },
    newMulCancel() {
      this.newMulForm = {
        classId: '',
        file: ''
      };
      this.showNewMulStatus = false;
    }
  }
}
</script>

<style scoped lang="less">
.student-query {
  display: flex;
  justify-content: space-between;
}
</style>
